import React, { MouseEvent } from "react";
import ButtonSpinner, { SpinnerTheme } from "./ButtonSpinner";

export enum ButtonStyle {
    Primary,
    Secondary
}

interface Props {
    loading: boolean;
    submit?: boolean;
    buttonStyle?: ButtonStyle;
    action?: Function;
    disabled?: boolean;
    disabledTooltip?: string;
    children: React.ReactNode;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}

function StateButton(props: Props) {
    const isSecondary = props.buttonStyle === ButtonStyle.Secondary;

    const computedClass = [
        isSecondary ? "secondary" : "",
        props.className ?? ""
    ]
        .filter(Boolean)
        .join(" ");

    function handleClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (props.action) {
            props.action();
        }
        if (props.onClick) {
            props.onClick(e);
        }
    }

    const isDisabled = props.loading || !!props.disabled;
    const buttonTitle = isDisabled ? props.disabledTooltip ?? "" : "";

    return (
        <button
            type={props.submit ? "submit" : "button"}
            className={computedClass}
            disabled={isDisabled}
            onClick={props.submit ? undefined : handleClick}
            title={buttonTitle}
        >
            <ButtonSpinner
                enabled={props.loading}
                theme={isSecondary ? SpinnerTheme.Dark : SpinnerTheme.Light}
            />
            {props.children}
        </button>
    );
}

export default StateButton;
