import React, { useRef, useState } from "react";
import { composeUrl } from "../../utils/api";
import { useAuth } from "react-oidc-context";
import { getClaimValue, hasRoleClaim } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { useForm } from "react-hook-form";
import { Tabs, Tab, TabContent } from "../Shared/Tabs";
import { useNavigate } from "react-router-dom";
import useInterval from "../Shared/Hooks/useInterval";
import WellKnownRoles from "../../models/wellKnownRoles";
import MemberBulkUpload from "./MemberBulkUpload";
import MemberSingleUpload from "./MemberSingleUpload";
import MemberSearch from "./MemberSearch";
import refreshIcon from "../../images/refresh.svg";
import checkCircle from "../../images/check-circle.svg";
import xCircle from "../../images/x-circle.svg";
import alertCircle from "../../images/alert-circle.svg";

export enum MemberFileStatusId {
    New = 1,
    Processing,
    Review,
    Confirming,
    Confirmed,
    Abandoned,
    Error
}

interface MemberFile {
    id: number;
    filename: string;
    statusId: MemberFileStatusId;
    statusName: string;
    uploadedOn: Date;
    reviewedOn: Date;
    new: number;
    updated: number;
    removed: number;
    unchanged: number;
}

interface FormData {
    fileName: string;
}

function Members() {
    const auth = useAuth();
    const navigate = useNavigate();
    const inputFile = useRef<HTMLInputElement | null>(null);

    // State for uploading / file handling
    const [isEmptyFile, setIsEmptyFile] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
    const [error, setError] = useState("");

    // Table/data states
    const [uploadedFiles, setUploadedFiles] = useState<MemberFile[]>([]);
    const [tablePage, setTablePage] = useState<number>(1);
    const [maxTablePage, setMaxTablePage] = useState<number>(1);

    // Auto-refresh states
    const [intervalDelay] = useState(5000);
    const [shouldAutoRefresh, setShouldAutoRefresh] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState("1");

    const methods = useForm<FormData>();
    const paginationLimit = 10;
    const processingStatuses: MemberFileStatusId[] = [
        MemberFileStatusId.New,
        MemberFileStatusId.Processing,
    ];

    const hasReviewFile = uploadedFiles.some(
        (file) => file.statusId === MemberFileStatusId.Review
    );

    const secondTabTitle = hasReviewFile
        ? "Previous Files & Changes (1 in review)"
        : "Uploaded Files & Changes";

    useInterval(() => refresh(), shouldAutoRefresh ? intervalDelay : null);

    React.useEffect(() => {
        if (!hasRoleClaim(auth.user, WellKnownRoles.OrganisationMemberManager)) {
            navigate("/access-denied");
        }
        refresh();
    }, []);

    function refresh() {
        setError("");
        const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles`), {
            headers: [["Authorization", `Bearer ${auth.user?.access_token}`]],
            method: "GET",
        })
            .then((response) => response.json() as Promise<MemberFile[]>)
            .then((data) => {
                let sortedUploads = data.sort((a, b) => { return (new Date(a.uploadedOn).valueOf() - new Date(b.uploadedOn).valueOf()) * -1 });
                if (sortedUploads.length > 0) {
                    switch (sortedUploads[0].statusId) {
                        case MemberFileStatusId.New:
                        case MemberFileStatusId.Processing:
                        case MemberFileStatusId.Review:
                        case MemberFileStatusId.Confirming:
                            setUploadDisabled(true);
                            break;
                        case MemberFileStatusId.Error:
                        case MemberFileStatusId.Abandoned:
                        case MemberFileStatusId.Confirmed:
                            setUploadDisabled(false);
                            break;
                    }
                    setTablePagination(sortedUploads);
                    setUploadedFiles(sortedUploads);
                } else {
                    setUploadDisabled(false);
                }

                setShouldAutoRefresh(data.filter(f =>
                    f.statusId === MemberFileStatusId.New ||
                    f.statusId === MemberFileStatusId.Processing ||
                    f.statusId === MemberFileStatusId.Confirming).length > 0);
            });
    }

    function setTablePagination(memberFiles: MemberFile[]) {
        const maxPage = Math.ceil(memberFiles.length / paginationLimit);
        setMaxTablePage(maxPage);
    }
    
    async function handleUpload(file: File) {
        setError("");
        setIsEmptyFile(false);

        if (file.size === 0) {
            setIsEmptyFile(true);
            return;
        }

        setUploading(true);
        setUploadDisabled(true);

        try {
            const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
            const formData = new FormData();
            formData.append("file", file, file.name);

            const response = await fetch(composeUrl(`organisations/${orgUuid}/memberFiles`), {
                headers: [["Authorization", `Bearer ${auth.user?.access_token}`]],
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                refresh();
                setActiveTabKey("2");
            } else {
                const responseBody = await response.json();
                if (responseBody.code && responseBody.message) {
                    setError(responseBody.message);
                } else {
                    setError("Sorry an error has occurred");
                }
            }
        } catch (e) {
            setError("Sorry an error has occurred");
        }

        setUploading(false);
        setUploadDisabled(false);
    }

    function getStatusElement(memberFile: MemberFile): JSX.Element {
        let icon = undefined;
        let iconAlt = "";
        let additionalClasses = "";

        switch (memberFile.statusId) {
            case MemberFileStatusId.New:
            case MemberFileStatusId.Processing:
            case MemberFileStatusId.Confirming:
                icon = refreshIcon;
                iconAlt = "Processing";
                additionalClasses = 'animate-spin';
                break;
            case MemberFileStatusId.Confirmed:
                icon = checkCircle;
                iconAlt = "Confirmed";
                break;
            case MemberFileStatusId.Error:
                icon = alertCircle;
                iconAlt = "Error";
                break;
            case MemberFileStatusId.Abandoned:
                icon = xCircle;
                iconAlt = "Abandoned";
                break;
            default:
                break;
        }

        return (
            <div className="leading-8 flex flex-row items-center">
                {icon && <img src={icon} alt={iconAlt} className={`mr-sm h-lg ${additionalClasses}`} />}
                {processingStatuses.includes(memberFile.statusId)
                    ? MemberFileStatusId[MemberFileStatusId.Processing]
                    : memberFile.statusId === MemberFileStatusId.Review ? (
                        <button
                            className="flex max-h-xl max-w-[8rem] items-center justify-center font-sans-co"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`review/${memberFile.id}`);
                            }}
                        >
                            Review
                        </button>
                    ) : (
                        MemberFileStatusId[memberFile.statusId]
                    )}
            </div>
        );
    }

    function navigateToDetails(memberFileId: number) {
        navigate(`details/${memberFileId}`);
    }

    return (
        <form onSubmit={methods.handleSubmit(() => {
        })}>
            <div className="mb-xl">
                <h1>Members</h1>
                <p>Add, remove, update scheme members</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div id="BulkMemberUploadContainer" className="mb-xl">
                    <MemberBulkUpload
                        onUpload={handleUpload}
                        setError={setError}
                        uploading={uploading}
                        disabled={uploadDisabled}
                        error={error}
                        isEmptyFile={isEmptyFile}
                        clearError={() => setError("")}
                    />
                </div>

                <div id="singleMemberUploadContainer" className="mb-xl">
                    <MemberSingleUpload disabled={uploadDisabled} />
                </div>
            </div>


            <Tabs
                activeTabKey={activeTabKey}
                onTabSelect={setActiveTabKey}
            >
                <Tab tabKey="1" name="Search Members"/>
                <Tab tabKey="2" name={secondTabTitle} />

                <TabContent associatedTabKey="1">
                    <div id="memberSearchContainer" className="w-full">
                        <MemberSearch />
                    </div>
                </TabContent>

                <TabContent associatedTabKey="2">
                    <table id="memberFileTable" className="w-full">
                        <thead className="bg-super-light-grey">
                        <tr className="h-2xl text-left text-primary-text">
                            <th className="px-md py-sm border-b-2 border-primary-text rounded-l-md"></th>
                            <th className="px-md py-sm border-b-2 border-primary-text rounded-l-md">
                                Name
                            </th>
                            <th className="px-md py-sm border-b-2 border-primary-text">Status</th>
                            <th className="px-md py-sm border-b-2 border-primary-text">New</th>
                            <th className="px-md py-sm border-b-2 border-primary-text">Removed</th>
                            <th className="px-md py-sm border-b-2 border-primary-text">Updated</th>
                            <th className="px-md py-sm border-b-2 border-primary-text rounded-r-md">
                                Unchanged
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {uploadedFiles.length > 0 &&
                            uploadedFiles
                                .slice((tablePage - 1) * paginationLimit, tablePage * paginationLimit)
                                .map((memberFile, index) => (
                                    <tr
                                        key={index}
                                        className="text-left bg-pure-white even:bg-super-light-grey h-2xl"
                                    >
                                        <td className="px-md py-sm w-4xl border-y-2 border-background-blu">
                                            {new Date(memberFile.uploadedOn).toLocaleDateString()}
                                        </td>

                                        <td className="px-md py-sm border-y-2 border-background-blu rounded-l-md">
                                            <a
                                                className="hover:cursor-pointer"
                                                onClick={() => navigateToDetails(memberFile.id)}
                                            >
                                                {memberFile.filename === "single.csv" ? "Single upload" : memberFile.filename}
                                            </a>
                                        </td>
                                        <td className="px-md py-sm border-y-2 border-background-blu">
                                            {getStatusElement(memberFile)}
                                        </td>
                                        <td className="px-md py-sm w-4xl border-y-2 border-background-blu">
                                            {memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.new}
                                        </td>
                                        <td className="px-md py-sm w-4xl border-y-2 border-background-blu">
                                            {memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.removed}
                                        </td>
                                        <td className="px-md py-sm w-4xl border-y-2 border-background-blu">
                                            {memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.updated}
                                        </td>
                                        <td
                                            className="px-md py-sm w-4xl border-y-2 border-background-blu rounded-r-md"
                                        >
                                            {memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.unchanged}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    <div className="flex flex-row mt-lg ml-md">
                        {tablePage > 1 ? (
                            <a
                                className="hover:cursor-pointer text-mid-grey mr-md"
                                onClick={() => setTablePage(tablePage - 1)}
                            >
                                {"< Previous"}
                            </a>
                        ) : (
                            <p className="text-light-grey mr-md">{"< Previous"}</p>
                        )}
                        {tablePage < maxTablePage ? (
                            <a
                                className="hover:cursor-pointer text-mid-grey"
                                onClick={() => setTablePage(tablePage + 1)}
                            >
                                {"Next >"}
                            </a>
                        ) : (
                            <p className="text-light-grey">{"Next >"}</p>
                        )}
                    </div>
                </TabContent>
            </Tabs>
        </form>
    );
}

export default Members;
