import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import { useForm } from "react-hook-form";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";

interface AddMemberModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onAddSuccess: () => void;
    onAddFailure: (errorMsg: string) => void;
    setLoading: (value: boolean) => void;
}

type MemberFormData = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
};

function AddMemberModal({
                            isOpen,
                            setIsOpen,
                            onAddSuccess,
                            onAddFailure,
                            setLoading
                        }: AddMemberModalProps) {
    const auth = useAuth();
    const [error, setError] = useState<string>();

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm<MemberFormData>({
        mode: "onBlur",
        reValidateMode: "onBlur",
    });

    const validateRequired = (value: string) => !!value.trim();

    const onSubmit = async (data: MemberFormData) => {
        setLoading(true);
        setError("");

        try {
            const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
            const email = data.email.trim();
            const externalId = data.id.trim();

            const existsResponse = await fetch(
                composeUrl(`organisations/${orgUuid}/members/memberAlreadyExists?email=${encodeURIComponent(email)}&externalId=${encodeURIComponent(externalId)}`),
                {
                    headers: [
                        ['Authorization', `Bearer ${auth.user?.access_token}`],
                        ['Content-Type', 'application/json']
                    ],
                    method: 'GET'
                }
            );

            if (existsResponse.ok) {
                const { existsByEmail, existsByExternalId } = await existsResponse.json();
                if (existsByEmail || existsByExternalId) {
                    const messages = [];
                    if (existsByEmail) messages.push("Email is already in use.");
                    if (existsByExternalId) messages.push("Employee ID is already in use.");
                    setError(messages.join(" "));
                    onAddFailure(messages.join(" "));
                    setLoading(false);
                    cancel();
                    return;
                }
            }

            const [year, month, day] = data.dateOfBirth.trim().split("-");
            const formattedDate = `${day}/${month}/${year}`;
            const payload = {
                id: externalId,
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
                email,
                dateOfBirth: formattedDate,
            };

            const createResponse = await fetch(composeUrl(`organisations/${orgUuid}/members/`), {
                headers: [
                    ['Authorization', `Bearer ${auth.user?.access_token}`],
                    ['Content-Type', 'application/json']
                ],
                method: 'POST',
                body: JSON.stringify(payload)
            });

            if (!createResponse.ok) {
                const responseBody = await createResponse.json().catch(() => null);
                const msg = responseBody?.message || "An error occurred while creating the member.";
                setError(msg);
                onAddFailure(msg);
            } else {
                onAddSuccess();
                cancel();
            }
        } catch {
            onAddFailure("An unknown error occurred while creating the member.");
        } finally {
            setLoading(false);
        }
    };

    const cancel = () => {
        reset();
        setError("");
        setIsOpen(false);
    };

    return (
        <OkCancelModal
            title="Add Member"
            okLabel="Add"
            okClick={handleSubmit(onSubmit)}
            cancelLabel="Cancel"
            cancelClick={cancel}
            show={isOpen}
            errorMessage={error}
        >
            <div className="mb-xl w-96 text-left mb-2 mx-auto">
                <form>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold mb-2">Employee ID</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.id ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("id", {
                                required: true,
                                validate: validateRequired,
                            })}
                        />
                        {errors.id && <p className="mt-1 text-p2 text-error-red">Value required</p>}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold mb-2">First Name</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.firstName ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("firstName", {
                                required: true,
                                maxLength: 80,
                                validate: validateRequired,
                            })}
                        />
                        {errors.firstName && (
                            <p className="mt-1 text-p2 text-error-red">
                                {errors.firstName.type === "required" && "Value required"}
                                {errors.firstName.type === "maxLength" && "Max length exceeded"}
                                {errors.firstName.type === "validate" && "Value required"}
                            </p>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold">Last Name</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.lastName ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("lastName", {
                                required: true,
                                maxLength: 100,
                                validate: validateRequired,
                            })}
                        />
                        {errors.lastName && (
                            <p className="mt-1 text-p2 text-error-red">
                                {errors.lastName.type === "required" && "Value required"}
                                {errors.lastName.type === "validate" && "Value required"}
                                {errors.lastName.type === "maxLength" && "Max length exceeded"}
                            </p>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold">Email</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.email ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("email", {
                                required: true,
                                validate: validateRequired,
                                pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                        />
                        {errors.email && (
                            <p className="mt-1 text-p2 text-error-red">Valid email address required</p>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold">Date of Birth</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.dateOfBirth ? "border-error-red border-2" : ""
                            }`}
                            type="date"
                            {...register("dateOfBirth", {
                                required: true,
                                validate: validateRequired,
                            })}
                        />
                        {errors.dateOfBirth && (
                            <p className="mt-1 text-p2 text-error-red">Value required</p>
                        )}
                    </div>
                </form>
            </div>
        </OkCancelModal>
    );
}

export default AddMemberModal;
