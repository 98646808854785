import React, { useState, useEffect } from "react";
import Tab from "./Tab";
import TabContent from "./TabContent";

interface TabsProps {
    children: JSX.Element[];
    activeTabKey?: string;
    onTabSelect?: (tabKey: string) => void;
}

function Tabs({ children, activeTabKey, onTabSelect }: TabsProps) {
    const [internalSelectedTab, setInternalSelectedTab] = useState<string>();
    const tabs = children.filter((c) => c.props.tabKey);
    const tabContent = children.filter((c) => c.props.associatedTabKey);

    useEffect(() => {
        if (tabs.length > 0 && !activeTabKey) {
            const activeTabs = tabs.filter((t) => !t.props.disabled);
            setInternalSelectedTab(
                activeTabs.length > 0 ? activeTabs[0].props.tabKey : tabs[0].props.tabKey
            );
        }
    }, [tabs, activeTabKey]);

    const selectedTabKey = activeTabKey ?? internalSelectedTab;

    function handleTabSelected(tabKey: string) {
        onTabSelect?.(tabKey);
        if (!activeTabKey) {
            setInternalSelectedTab(tabKey);
        }
    }

    const generateTabs = tabs.map((t, i) => (
        <Tab
            key={i}
            name={t.props.name}
            tabKey={t.props.tabKey}
            tabSelected={() => handleTabSelected(t.props.tabKey)}
            disabled={t.props.disabled}
            selectedTabKey={selectedTabKey}
        />
    ));

    const generateTabContent = tabContent.map((tc, i) => (
        <TabContent
            key={i}
            associatedTabKey={tc.props.associatedTabKey}
            selectedTabKey={selectedTabKey}>
            {tc.props.children}
        </TabContent>
    ));

    return (
        <div>
            <div className="flex flex-row items-start bg-pure-white">{generateTabs}</div>
            <div>{generateTabContent}</div>
        </div>
    );
}

export default Tabs;
