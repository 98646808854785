import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import { useForm } from "react-hook-form";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { composeUrl } from "../../utils/api";

interface EditMemberModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    member: {
        externalId: string;
        firstName: string;
        lastName: string;
        email: string;
    } | null;
    onEditSuccess: () => void;
    onEditFailure: () => void;
    setLoading: (value: boolean) => void;
}

type MemberEditFormData = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
};

function EditMemberModal({
                             isOpen,
                             setIsOpen,
                             member,
                             onEditSuccess,
                             onEditFailure,
                             setLoading
                         }: EditMemberModalProps) {
    const auth = useAuth();
    const [error, setError] = useState<string>();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<MemberEditFormData>({
        mode: "onBlur",
        reValidateMode: "onBlur",
    });

    useEffect(() => {
        if (member && isOpen) {
            setValue("id", member.externalId);
            setValue("firstName", member.firstName);
            setValue("lastName", member.lastName);
            setValue("email", member.email);
        }
    }, [member, isOpen, setValue]);

    const validateRequired = (value: string) => !!value.trim();

    const onSubmit = async (data: MemberEditFormData) => {
        setLoading(true);
        setError("");

        try {
            const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
            const payload = {
                id: data.id.trim(),
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
                email: data.email.trim()
            };
            const response = await fetch(composeUrl(`organisations/${orgUuid}/members/update`), {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                const responseBody = await response.json().catch(() => null);
                const msg = responseBody?.message || "An error occurred while editing the member.";
                setError(msg);
                onEditFailure();
            } else {
                onEditSuccess();
                cancel();
            }
        } catch {
            setError("An unknown error occurred while editing the member.");
            onEditFailure();
        } finally {
            setLoading(false);
        }
    };

    const cancel = () => {
        reset();
        setError("");
        setIsOpen(false);
    };

    return (
        <OkCancelModal
            title="Edit Member"
            okLabel="Save"
            okClick={handleSubmit(onSubmit)}
            cancelLabel="Cancel"
            cancelClick={cancel}
            show={isOpen}
            errorMessage={error}
        >
            <div className="mb-xl w-96 text-left mb-2 mx-auto">
                <form>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold mb-4">Employee ID: <span className="font-normal">{member?.externalId}</span></label>
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold mb-2">First Name</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.firstName ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("firstName", {
                                required: true,
                                maxLength: 80,
                                validate: validateRequired,
                            })}
                        />
                        {errors.firstName && (
                            <p className="mt-1 text-p2 text-error-red">
                                {errors.firstName.type === "required" && "Value required"}
                                {errors.firstName.type === "maxLength" && "Max length exceeded"}
                                {errors.firstName.type === "validate" && "Value required"}
                            </p>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold">Last Name</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.lastName ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("lastName", {
                                required: true,
                                maxLength: 100,
                                validate: validateRequired,
                            })}
                        />
                        {errors.lastName && (
                            <p className="mt-1 text-p2 text-error-red">
                                {errors.lastName.type === "required" && "Value required"}
                                {errors.lastName.type === "validate" && "Value required"}
                                {errors.lastName.type === "maxLength" && "Max length exceeded"}
                            </p>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-p1 font-bold">Email</label>
                        <input
                            className={`border border-solid rounded-md w-full pl-2 ${
                                errors.email ? "border-error-red border-2" : ""
                            }`}
                            type="text"
                            {...register("email", {
                                required: true,
                                validate: validateRequired,
                                pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                        />
                        {errors.email && (
                            <p className="mt-1 text-p2 text-error-red">Valid email address required</p>
                        )}
                    </div>
                </form>
            </div>
        </OkCancelModal>
    );
}

export default EditMemberModal;
