import React from "react";
import OkCancelModal from "../Shared/Modal/OkCancelModal";
import {MemberSummary} from "./MemberSearch";

interface ConfirmDeleteMemberModalProps {
    show: boolean;
    member?: MemberSummary;
    onConfirm: () => void;
    onCancel: () => void;
    errorMessage?: string;
}

export default function ConfirmDeleteMemberModal({
                                                     show,
                                                     member,
                                                     onConfirm,
                                                     onCancel,
                                                     errorMessage
                                                 }: ConfirmDeleteMemberModalProps) {
    
    const employeeId = member?.externalId || "";
    const fullName = member ? `${member.firstName} ${member.lastName}` : "";
    const email = member?.email || "";

    return (
        <OkCancelModal
            title="Confirm Delete"
            okLabel="Confirm"
            okClick={onConfirm}
            cancelLabel="Cancel"
            cancelClick={onCancel}
            show={show}
            errorMessage={errorMessage}
        >
            <div className="w-full">
                <p className="mb-4">
                    Are you sure you want to delete the following user?
                </p>
                <ul className="list-disc list-inside space-y-1">
                    <li><strong>Employee ID:</strong> {employeeId}</li>
                    <li><strong>Name:</strong> {fullName}</li>
                    <li><strong>Email:</strong> {email}</li>
                </ul>
                <p className="my-4 text-sm text-error-red">
                    This action cannot be undone.
                </p>
            </div>
        </OkCancelModal>
    );
}
