import React, { useState } from 'react';
import StateButton from "../Shared/StateButton/StateButton";
import AddMemberModal from "./AddMemberModal";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

interface MemberSingleUploadProps {
    disabled: boolean;
}

const MemberSingleUpload: React.FC<MemberSingleUploadProps> = ({ disabled }) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    
    const handleAddSuccess = () => {
        setMessage("Member created successfully!");
    };
    
    const handleAddFailure = (errorMessage: string) => {
        setMessage(`Failed to create member: ${errorMessage}`);
    };

    const handleCreate = () => {
        setOpenModal(true);
    };

    return (
        <LoadingSpinner loading={loading}>
            <div
                id="singleMemberAdd"
                className="h-full bg-super-light-grey rounded-xl p-4 flex flex-col justify-center items-center"
            >
                <div className="mb-md text-center">
                    <h3>Add single member</h3>
                    <p>Upload a single member, without using a CSV file.</p>
                </div>
                
                <div>
                    <p>{message}</p>
                </div>

                <div className="w-full flex flex-row items-center justify-around gap-lg mt-md ">
                    <StateButton
                        submit={false}
                        className="text-pure-white bg-dark-grey"
                        disabled={disabled}
                        disabledTooltip="Cannot create a member while another file is being processed."
                        loading={false}
                        onClick={handleCreate}
                    >
                        Add
                    </StateButton>
                </div>

                <AddMemberModal
                    isOpen={openModal}
                    setIsOpen={setOpenModal}
                    onAddSuccess={handleAddSuccess}
                    onAddFailure={handleAddFailure}
                    setLoading={setLoading}
                />
            </div>
        </LoadingSpinner>
    );
};

export default MemberSingleUpload;
