import React, {ChangeEvent, useRef, useState} from 'react';
import StateButton, {ButtonStyle} from "../Shared/StateButton/StateButton";
import uploadIcon from "../../images/upload.svg";

interface MemberFileUploadProps {
    onUpload: (file: File) => Promise<void>;
    setError: (message: string) => void;
    uploading: boolean;
    disabled: boolean;
    error: string;
    isEmptyFile: boolean;
    clearError: () => void;
}

function MemberBulkUpload({
                              onUpload,
                              setError,
                              uploading,
                              disabled,
                              error,
                              isEmptyFile,
                              clearError
                          }: MemberFileUploadProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const inputFile = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        inputFile.current?.click();
    };

    const fileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        clearError();
        const file = event.target.files?.item(0);
        if (file) {
            setSelectedFile(file);
        }
    };

    async function handleUpload() {
        clearError();
        if (!selectedFile) {
            setError("Please select a CSV file to upload.");
            return;
        }
        await onUpload(selectedFile);
        setSelectedFile(null);
        if (inputFile.current) {
            inputFile.current.value = "";
        }
    }

    return (
        <div className="h-full bg-super-light-grey rounded-xl p-4">
            <div className="mb-md text-center">
                <h3>Bulk upload</h3>
                <p>Update all members’ data by a single CSV file upload.</p>
            </div>
            <div className="flex flex-row items-center">
                <input
                    type="file"
                    onChange={fileSelected}
                    hidden
                    accept="text/csv"
                    ref={inputFile}
                />
                <input
                    className="border-2 border-primary-text w-full p-xs mb-xs"
                    type="text"
                    value={selectedFile?.name ?? ''}
                    placeholder="Select CSV file...."
                    onClick={handleClick}
                    readOnly
                />
                <img src={uploadIcon} alt="Upload_Icon" className="ml-sm h-lg" />
            </div>

            {isEmptyFile && (
                <p className="text-p2 text-error-red">File is empty.</p>
            )}
            {error && (
                <p className="text-p2 text-error-red">{error}.</p>
            )}

            <div className="w-full flex flex-row items-center justify-around gap-lg mt-md ">
                <StateButton
                    submit={false}
                    className="text-pure-white bg-dark-grey"
                    disabled={disabled}
                    loading={uploading}
                    onClick={() => handleUpload()}
                >
                    Upload
                </StateButton>
                <div>
                    <a href="/files/Member_File_Template.csv" download className="btn btn-sm btn-primary">
                        Download template
                    </a>
                </div>
            </div>
        </div>
    );
}

export default MemberBulkUpload;
